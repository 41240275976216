/* ContactPage.css */

.contact-page-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  max-width: 1200px;
  margin: auto;
  gap: 20px;
  margin-bottom: 30px;
}

.contact-image-container {
  width: 100%;
}

.contact-image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.contact-form-container h2 {
  font-family: "Georgia", serif;
  font-style: italic;
}

.contact-form-container h2 .sweet-word {
  font-family: "Brokefold Sans", sans-serif;
  font-style: normal;
  color: #d5b681;
}

.form-label {
  font-family: "Sofia Pro Bold";
  color: #6fa58f;
  margin-bottom: 5px; /* Or adjust as needed */
  display: block; /* To ensure the label appears on a new line */
  text-transform: uppercase;
}

.contact-form-container p {
  font-family: "Georgia", serif;
  font-style: italic;
  color: #000;
}

.contact-form {
  display: flex;
  flex-direction: column;
}

.contact-form input,
.contact-form textarea {
  font-family: "Sofia Pro", sans-serif;
  color: #6fa58f;
  border: 1px solid #ccc;
  border-radius: 15px;
  padding: 15px;
  margin-bottom: 10px;
}

.contact-form button {
  font-family: "Sofia Pro", sans-serif;
  font-weight: bold;
  color: white;
  background-color: #f7b3a2;
  border: none;
  border-radius: 20px;
  padding: 15px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: darken(#f7b3a2, 10%);
}

/* General style for submission messages */
.submission-message {
  margin-top: 20px;
  font-weight: bold;
  font-family: Georgia, sans-serif;
}

/* Specific styles for success and error messages */
.submission-message.success {
  color: green; /* Green for success messages */
  font-family: Georgia, sans-serif;
}

.submission-message.error {
  color: red; /* Red for error messages */
  font-family: Georgia, sans-serif;
}
/* Add media queries as needed for responsiveness */

@media (max-width: 768px) {
  .contact-page-container {
    grid-template-columns: 1fr;
  }

  .contact-image-container {
    display: none;
  }
}
