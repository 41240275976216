.heroCarousel-container {
  position: relative;
  max-width: 100%;
  margin-top: 8rem; /* Added space between the navbar and carousel */
}

.heroCarousel-title {
  position: absolute;
  width: 100%;
  top: -10%; /* Adjust this to control the vertical positioning on desktop */
  left: 0;
  font-family: "Nouveau Regular";
  font-size: 8rem; /* Large font size for the title on desktop */
  font-weight: normal;
  color: #f7b3a2; /* Color of the title */
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.5); /* Text shadow for better readability */
  padding: 0.1rem 0.1rem; /* Padding around the text */
  text-align: center; /* Centers the text horizontally */
  margin: 0; /* Remove default margin */
  z-index: 2; /* Ensure the title is above the image */
  margin: 0; /* Remove default margin */
}

.heroCarousel-image {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
  display: block;
}

/* Smaller text for 'Del' */
.smaller {
  font-size: 0.7em; /* Adjust the size of 'Del' relative to 'Gelateria' and 'Centro' */
  color: #f7b3a2; /* Lighter color for 'Del' */
}

/* Media queries for various breakpoints */
@media (min-width: 1200px) {
  /* Large desktops */
  .heroCarousel-title {
    font-size: 9rem; /* Adjust font size as needed */
    top: -10%; /* Position the title above the image */
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  /* Small desktops */
  .heroCarousel-title {
    font-size: 6rem; /* Adjust font size as needed */
    top: -10%; /* Position the title above the image */
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  /* Tablets */
  .heroCarousel-title {
    font-size: 5rem; /* Adjust font size as needed */
    top: -10%; /* Position the title above the image */
  }
}

@media (max-width: 767px) {
  /* Mobile phones */
  .heroCarousel-title {
    font-size: 3rem; /* Adjust font size as needed */
    top: -13%; /* Position the title properly on mobile devices */
  }
}

@media (max-width: 480px) {
  /* Smaller mobile phones */
  .heroCarousel-title {
    font-size: 2.3rem; /* Adjust font size as needed */
    top: -13%; /* Keep the title in the same position */
  }
}
