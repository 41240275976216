body {
  overflow-x: hidden;
}
.about-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
}

/* Hours, Special Events & Our Why Headings */
.about-container h2 {
  color: #978431;
}

/* Description under logo and paragraphs under each heading */
.about-container p {
  font-family: "Georgia", serif;
}

.about-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.store-logo {
  max-width: 150px;
  height: auto; /* This will maintain the aspect ratio of the image */
  margin-bottom: 20px;
  display: block;
  align-self: flex-start; /* Aligns the logo to the start of the flex container */
}

.about-description {
  font-family: "Georgia", serif;
  color: #978431;
  text-align: justify;
  font-style: italic;
}

.info-section {
  border-left: 2px solid #70a58f;
  padding-left: 20px;
  margin-left: 20px;
}

.info-section h2 {
  font-family: "Sofia Pro Bold", serif;
  color: #f8b3a2;
  margin: 10px 0;
  font-size: 16px;
}

.about-special-events-text {
  font-family: "Georgia", serif; /* Or whichever font you're using */
  color: #000; /* Your text color */
  padding-bottom: 3px; /* Space between text and underline */
  border-bottom: 3px dashed #978431; /* Dashed line styling */
  display: inline; /* Only underline the text, not the full container width */
  cursor: pointer; /* Change cursor to indicate it's clickable if it's a link */
  font-style: italic;
}

.event-card {
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  margin: 1rem 0;
  padding: 1rem;
  font-family: Georgia, "Times New Roman", Times, serif;
  cursor: pointer;
}

.event-card:hover {
  transform: scale(1.02);
  transition: transform 0.3s ease-in-out;
}

.event-card .event-title {
  color: red;
  margin-bottom: 0.75rem;
}

.event-card .current-events-link {
  color: #007bff;
  text-decoration: none;
}

.color-bar {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 70%; /* Adjust the width of the color bar so it centers correctly */
}

/* Responsive Layout */

/* Medium screens (tablets) */
@media screen and (max-width: 1024px) {
  .color-bar {
    max-width: 80%; /* Slightly larger percentage since the screen is smaller */
  }
}

/* Small screens (phones) */
@media screen and (max-width: 768px) {
  .color-bar {
    max-width: 70%; /* Even larger percentage for small screens */
    height: auto; /* Adjust height to maintain aspect ratio */
  }
}

/* Mobile devices */
@media screen and (max-width: 480px) {
  .color-bar {
    max-width: 60%; /* Adjust width for smaller screens */
  }
  .store-logo {
    margin-left: auto;
    margin-right: auto;
  }
}
/* Responsive adjustments */
@media (max-width: 768px) {
  .about-container {
    grid-template-columns: 1fr;
    padding: 20px;
  }

  .info-section {
    border-left: none;
    padding-left: 0;
    margin-left: 0;
  }

  .store-logo {
    max-width: 150px;
  }
}
