/* Art Nouveau Font */
@font-face {
  font-family: "Nouveau Regular";
  src: url("../public/fonts/Nouveau Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/* Gelateria Del Centro Brand Typeface Font */

@font-face {
  font-family: "GDC-Blunt-Regular";
  src: url("../public//fonts/GDC-Blunt-Regular.otf") format("opentype");
}
/* Font for everything else */
@font-face {
  font-family: "Sofia Pro Regular";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro Regular"),
    url("../public/fonts/Sofia Pro Regular Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro Italic"),
    url("../public/fonts/Sofia Pro Regular Italic Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro ExtraLight";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro ExtraLight"),
    url("../public/fonts/Sofia Pro ExtraLight Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro UltraLight";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro UltraLight"),
    url("../public/fonts/Sofia Pro UltraLight Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro ExtraLight Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro ExtraLight Italic"),
    url("../public/fonts/Sofia Pro ExtraLight Italic Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro UltraLight Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro UltraLight Italic"),
    url("../public/fonts/Sofia Pro UltraLight Italic Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro Light";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro Light"),
    url("../public/fonts/Sofia Pro Light Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro Light Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro Light Italic"),
    url("../public/fonts/Sofia Pro Light Italic Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro Medium";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro Medium"),
    url("../public/fonts/Sofia Pro Medium Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro Medium Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro Medium Italic"),
    url("../public/fonts/Sofia Pro Medium Italic Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro SemiBold";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro SemiBold"),
    url("../public/fonts/Sofia Pro Semi Bold Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro SemiBold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro SemiBold Italic"),
    url("../public/fonts/Sofia Pro Semi Bold Italic Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro Bold";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro Bold"),
    url("../public/fonts/Sofia Pro Bold Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro Bold Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro Bold Italic"),
    url("../public/fonts/Sofia Pro Bold Italic Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro Black";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro Black"),
    url("../public/fonts/Sofia Pro Black Az.woff") format("woff");
}

@font-face {
  font-family: "Sofia Pro Black Italic";
  font-style: normal;
  font-weight: normal;
  src: local("Sofia Pro Black Italic"),
    url("../public/fonts/Sofia Pro Black Italic Az.woff") format("woff");
}

/* Our Favorites Section Special Font */

@font-face {
  font-family: "Brokefold Sans";
  font-style: normal;
  font-weight: bold;
  src: local("Brokefold Sans"),
    url("../public/fonts/BrokefoldSansBold-lg8oy.otf") format("opentype");
}
