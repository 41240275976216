body {
  margin: 0;
  padding: 0;
}

.navbar {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0;
  height: 70px;
  background: linear-gradient(to right, #6fa58f 70%, #e98787 30%);
  position: relative;
}

/* Scallop effect using pseudo-element */
.navbar::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 10px; /* Adjust the height for the size of the scallop */
  background: repeating-radial-gradient(
    circle at 50% 0,
    transparent 0,
    transparent 10px,
    #fff 11px,
    #fff 13px,
    transparent 14px
  );
  background-size: 20px 20px;
}

.navbar-left,
.navbar-right {
  display: flex;
  align-items: center;
}

.navbar-left {
  width: 70%;
}

.navbar-right {
  justify-content: center;
  width: 30%;
}

.navbar-address {
  font-family: "Sofia Pro SemiBold";
  color: white;
  margin: 0;
  padding: 0 10px;
}

.navbar-address em {
  font-family: "Georgia", serif;
  font-style: italic;
  text-transform: lowercase;
}

.home-icon,
.order-now {
  text-decoration: none;
}

.order-now {
  font-family: "Sofia Pro Regular";
  color: #ffffff;
  font-size: 1.6rem;
  position: relative;
  display: inline-block;
}

.order-now::after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  background: repeating-linear-gradient(
    to right,
    #f7b3a2,
    #f7b3a2 5px,
    transparent 5px,
    transparent 10px
  );
  position: absolute;
  bottom: -5px;
  left: 0;
}

a:-webkit-any-link {
  text-decoration: none;
}

.order-now a {
  text-decoration: none;
  color: inherit;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .navbar {
    height: 60px;
  }

  .navbar-left {
    display: none;
  }

  .navbar-right {
    width: 100%;
    background: #6fa58f;
  }
}
