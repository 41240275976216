.online-ordering-modal-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(
    247,
    179,
    162,
    0.92
  ); /* Updated background color with transparency */
  z-index: 1000; /* Ensure it's above other content */
  overflow-y: auto; /* Enables scrolling if the modal is taller than the screen */
}

.online-ordering-modal {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/blue-art-nouveau-frame-800x800.png");
  background-size: 100% 100%; /* Adjust this as needed */
  background-repeat: no-repeat;
  width: 800px; /* Assuming the image should be 800px wide */
  height: 800px; /* Assuming the image should be 800px high */
  margin: auto;
  max-height: 90vh; /* 90% of the viewport height */
  box-sizing: border-box;
  overflow-y: auto; /* Adds scroll to the modal content if it overflows */
}

.modal-title {
  font-family: "Brokefold Sans", sans-serif;
  font-size: 40px;
  color: #b68173;
}

.modal-divider {
  border: none;
  height: 2px;
  background-color: #d3907f;
  margin: 20px auto; /* Centers the line */
  width: 80%; /* Adjust width as needed */
}

.service-list {
  list-style: none;
  padding: 0;
}

.service-list li {
  font-family: "Sofia Pro Bold", sans-serif;
  color: #ffffff;
  margin: 20px 0;
}

.service-link {
  text-decoration: none;
  color: inherit; /* Inherits the white color from its parent */
  display: block; /* Makes the link fill the entire list item for easier clicking */
  border-bottom: 2px solid #d3907f; /* The horizontal line between services */
  padding: 10px 0; /* Space above and below text */
  margin-bottom: 1rem; /* Add some margin between the links */
  font-size: 44px;
}

.modal-close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  border: none;
  background: none;
  color: white;
  font-size: 1.5em;
  cursor: pointer;
  font-family: "Sofia Pro Bold", sans-serif;
}

/* Responsive Styles */

@media (max-width: 800px) {
  .online-ordering-modal {
    width: 90%; /* You can adjust this for smaller screens */
    height: auto; /* This will make the height responsive */
    padding: 10px; /* Less padding on smaller screens */
  }
}
