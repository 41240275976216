body {
  margin: 0;
  padding: 0;
}

.title {
  text-align: center;
  font-family: "Nouveau Regular";
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
}

.events-container .grid > section {
  padding: 20px;
  margin: 10px;
  background-color: rgba(255, 255, 255, 0.8); /* semi-transparent white */
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.events-container h1,
.events-container h2,
.events-container h3,
.events-container h4,
.events-container h5,
.events-container h6 {
  font-family: "Nouveau Regular";
  text-align: center;
}

.events-container > div {
  padding: 20px;
  margin: 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.inquiry-form form {
  display: flex;
  flex-direction: column;
}

.inquiry-form form label {
  display: flex;
  margin-bottom: 20px;
}

.inquiry-form form label span {
  width: 100px; /* Adjust this value as needed */
  margin-right: 10px;
}

.inquiry-form form input,
.inquiry-form form textarea {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-top: 5px; /* Add this line to add space between the label and the input field */
}

/* Button Style CSS */
.submit-button {
  background-color: #eb6368; /* Pink background */
  border: none; /* Remove border */
  color: white; /* White text */
  padding: 15px 32px; /* Some padding */
  text-align: center; /* Centered text */
  text-decoration: none; /* Remove underline */
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  cursor: pointer; /* Mouse pointer on hover */
  border-radius: 20px; /* Rounded corners */
  transition-duration: 0.4s; /* Transition */
  margin-bottom: 5%;
}
