/* OurFavorites.css */

.title-container {
  display: flex;
  justify-content: center;
  align-items: baseline;
}

.favorites-lowercase {
  font-size: 1.5em;
  color: #000000;
  font-family: "Georgia", serif;
  margin-right: 0.5em;
  font-style: italic; /* Make the text italic */
}

.favorites-uppercase {
  font-size: 3em;
  color: #d5b681;
  font-family: "Brokefold Sans", sans-serif;
  text-transform: uppercase;
}

.favorites {
  font-family: "Sofia Pro", sans-serif;
  text-align: center;
  max-width: 960px;
  margin: 0 auto;
  padding: 20px;
}

.favorites-title,
.favorites-subtitle {
  color: #c8102e;
}

.favorites-subtitle {
  font-size: 1.2em;
  color: #333;
  margin-bottom: 10px; /* Reduce the bottom margin */
  font-style: italic;
  font-family: "Georgia", serif;
}

.favorites-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5px;
  margin-top: 2em;
  cursor: pointer;
}

.favorite-item {
  background: #fff;
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
  width: 280px;
  height: 330px;
  margin: 20px auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative; /* Added to position the checkmark absolutely within */
  padding-bottom: 32px; /* Space for the checkmark */
  cursor: pointer; /* Change cursor to pointer on hover */
}

.favorite-image {
  width: 100%;
  height: auto; /* Adjust to auto for better aspect ratio control */
  object-fit: cover;
  display: block;
  border-top-left-radius: 20px; /* Apply a border-radius to the top-left corner of the image */
  border-top-right-radius: 20px; /* Apply a border-radius to the top-right corner of the image */
}

.favorite-label {
  display: flex;
  justify-content: center; /* Center label text and checkmark */
  align-items: center;
  padding: 15px;
  flex-grow: 1; /* Make label take up remaining space */
}

.favorite-name {
  color: #f8b3a2;
  font-weight: bold;
  font-size: 1.2em;
  font-family: "Sofia Pro Regular", sans-serif;
  padding: 0em 1em; /* Adjust padding for spacing */
  overflow: visible; /* Allow text to show completely */
  text-align: center;
}

.favorite-name-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.favorite-checkmark {
  background-color: #6fa58f;
  color: white;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-style: italic;
  font-family: "Georgia", serif;
  font-weight: bold;
  position: absolute;
  left: 50%;
  bottom: -16px; /* Adjust this value as needed to match the overlap in your design */
  transform: translateX(-50%);
}

.flavor-part {
  display: block; /* Ensures each part of the flavor name is on a new line */
  font-size: 1.2em; /* Adjust font-size as necessary */
  line-height: 1.2; /* Adjust line-height for spacing between the two parts */
}

.color-bar {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 70%; /* Adjust the width of the color bar so it centers correctly */
  margin-top: 40px; /* Add space above the color bar */
}

@media (max-width: 768px) {
  .favorites-grid {
    grid-template-columns: 1fr;
  }

  .favorite-item {
    width: auto;
    height: auto;
    margin: 10px;
  }

  .favorite-name-container {
    margin-top: 0.5em;
  }

  .favorite-checkmark {
    position: absolute;
    bottom: -16px; /* Adjust for mobile if needed */
  }

  .favorite-name {
    font-size: 1em; /* Adjust if needed for mobile */
  }
}

@media (max-width: 480px), (max-device-width: 480px) {
  .favorites-title,
  .favorites-subtitle,
  .favorite-name {
    font-size: smaller;
  }

  .favorite-item {
    padding: 15px;
  }

  .favorite-checkmark {
    font-size: 16px; /* Adjust for smaller devices */
    bottom: -16px; /* Maintain consistency */
  }
}
